export const getHeroPriceText = (selectedLocation) => {
    if (selectedLocation === 'CANADA') {
        return 'as low as CAD 12.50 per hour.'
    } else if (selectedLocation === 'US') {
        return 'as low as USD 7.8 per hour.'
    } else if (selectedLocation === 'UK') {
        return 'as low as BPS 6.25 per hour.'
    } else if (selectedLocation === 'AUS') {
        return 'as low as AUD 11.00 per hour.'
    }
}

export const getFirmTypeText = (selectedLocation) => {
    if (selectedLocation === 'CANADA') {
        return 'CPA and Bookkeeping firms including all business types'
    } else if (selectedLocation === 'US') {
        return 'Accounting and Bookkeeping firms including all business types'
    } else if (selectedLocation === 'UK') {
        return 'Accounting and Bookkeeping firms including all business types'
    } else if (selectedLocation === 'AUS') {
        return 'Accounting and Bookkeeping firms including all business types'
    }
}

export const getCountryTypeText = (selectedLocation) => {
    if (selectedLocation === 'CANADA') {
        return 'Canadian'
    } else if (selectedLocation === 'US') {
        return 'US based'
    } else if (selectedLocation === 'UK') {
        return 'UK based'
    } else if (selectedLocation === 'AUS') {
        return 'Australian'
    }
}