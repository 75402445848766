import { useEffect, useState } from 'react';
import './App.css';
import { getHeroPriceText, getCountryTypeText, getFirmTypeText } from './content';
import { AUTOMATION_SUB_CATEGORY, EXPERTISE_LIST, AUTOMATION_CATEGORY, PRICING_PLAN, SKILLS_LIST } from './listContent';
import Select, { components } from 'react-select'
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

function App() {
  const DEFAULT_LOCATION = { key: 'CANADA', name: 'Canada', img: require('./assets/flag/canada_icon.png') }
  const [selectedAutomation, setSelectedAutomation] = useState()
  const [selectedSubCategory, setSelectedSubCategory] = useState([])
  const [selectedLocation, setSelectedLocation] = useState(DEFAULT_LOCATION)
  const [expertiseList, setExpertiseList] = useState([])
  const [automationList, setAutomationList] = useState([])
  const [pricingList, setPricingList] = useState([])
  const [skillsList, setSkillsList] = useState([])
  const [priceLabel, setPriceLabel] = useState('')
  const [firmTypeLabel, setFirmTypeLabel] = useState('')
  const [countryLabel, setCountryLabel] = useState('')
  const LOCATION_LIST = [
    { key: 'CANADA', name: 'Canada', img: require('./assets/flag/canada_icon.png') },
    { key: 'US', name: 'USA', img: require('./assets/flag/america_icon.png') },
    { key: 'UK', name: 'UK', img: require('./assets/flag/united kingdom_icon.png') },
    { key: 'AUS', name: 'Australia', img: require('./assets/flag/australia_icon.png') },
  ]

  useEffect(() => {
    setSelectedLocation({
      value: DEFAULT_LOCATION.key,
      label: DEFAULT_LOCATION.name,
      data: DEFAULT_LOCATION,
    })
  }, [])

  let trim = (x) => {
    let value = String(x);
    return value.replace(/^\s+|\s+$/gm, "");
  };

  const isEmpty = (value) => {
    if (
      value === null ||
      value === undefined ||
      trim(value) === "" ||
      value.length === 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleCategoryClick = item => {
    setSelectedAutomation(item)
    let filteredSubCategory = AUTOMATION_SUB_CATEGORY.filter(e => e.categoryId === item.id && e.country === item.country)
    setSelectedSubCategory(filteredSubCategory)
  }

  const handleContactUsClick = () => {
    var subject = encodeURIComponent('Request Quotation | Fattu Concepts');
    var recipient = encodeURIComponent('contact@fattuconcepts.com');
    var mailtoLink = 'mailto:' + recipient + '?subject=' + subject;
    window.location.href = mailtoLink;
  }

  useEffect(() => {
    if (!isEmpty(selectedLocation)) {
      if (selectedLocation.key === 'CANADA') {
        let filteredExpertise = EXPERTISE_LIST.filter(e => e.country === 'CANADA')
        setExpertiseList(filteredExpertise)
        let filteredAutomationList = AUTOMATION_CATEGORY.filter(e => e.country === 'CANADA')
        setAutomationList(filteredAutomationList)
        let filteredPricingList = PRICING_PLAN.filter(e => e.country === 'CANADA')
        setPricingList(filteredPricingList)
        let filteredSkillList = SKILLS_LIST.filter(e => e.country === 'CANADA')
        setSkillsList(filteredSkillList)
        setPriceLabel(getHeroPriceText('CANADA'))
        setFirmTypeLabel(getFirmTypeText('CANADA'))
        setCountryLabel(getCountryTypeText('CANADA'))
      } else if (selectedLocation.key === 'US') {
        let filteredExpertise = EXPERTISE_LIST.filter(e => e.country === 'US')
        setExpertiseList(filteredExpertise)
        let filteredAutomationList = AUTOMATION_CATEGORY.filter(e => e.country === 'US')
        setAutomationList(filteredAutomationList)
        let filteredPricingList = PRICING_PLAN.filter(e => e.country === 'US')
        setPricingList(filteredPricingList)
        let filteredSkillList = SKILLS_LIST.filter(e => e.country === 'US')
        setSkillsList(filteredSkillList)
        setPriceLabel(getHeroPriceText('US'))
        setFirmTypeLabel(getFirmTypeText('US'))
        setCountryLabel(getCountryTypeText('US'))
      } else if (selectedLocation.key === 'UK') {
        let filteredExpertise = EXPERTISE_LIST.filter(e => e.country === 'UK')
        setExpertiseList(filteredExpertise)
        let filteredAutomationList = AUTOMATION_CATEGORY.filter(e => e.country === 'UK')
        setAutomationList(filteredAutomationList)
        let filteredPricingList = PRICING_PLAN.filter(e => e.country === 'UK')
        setPricingList(filteredPricingList)
        let filteredSkillList = SKILLS_LIST.filter(e => e.country === 'UK')
        setSkillsList(filteredSkillList)
        setPriceLabel(getHeroPriceText('UK'))
        setFirmTypeLabel(getFirmTypeText('UK'))
        setCountryLabel(getCountryTypeText('UK'))
      } else if (selectedLocation.key === 'AUS') {
        let filteredExpertise = EXPERTISE_LIST.filter(e => e.country === 'AUS')
        setExpertiseList(filteredExpertise)
        let filteredAutomationList = AUTOMATION_CATEGORY.filter(e => e.country === 'AUS')
        setAutomationList(filteredAutomationList)
        let filteredPricingList = PRICING_PLAN.filter(e => e.country === 'AUS')
        setPricingList(filteredPricingList)
        let filteredSkillList = SKILLS_LIST.filter(e => e.country === 'AUS')
        setSkillsList(filteredSkillList)
        setPriceLabel(getHeroPriceText('AUS'))
        setFirmTypeLabel(getFirmTypeText('AUS'))
        setCountryLabel(getCountryTypeText('AUS'))
      }
    }
  }, [selectedLocation])

  const Option = (props) => {
    return (
      <components.Option {...props}>
        <div className='select-row-container'>
          <div className='select-img-container'>
            <img src={props.data.data.img} alt={props.data.name} className='img' />
          </div>
          {props.data.data.name}
        </div>
      </components.Option>
    )
  };

  const SingleValue = ({ children, ...props }) => {
    console.log(props);
    return (
      <components.SingleValue {...props}>
        <div className='select-row-container'>
          <div className='select-img-container'>
            <img src={props.data.data.img} alt={props.data.name} className='img' />
          </div>
          {children}
        </div>
      </components.SingleValue>
    )
  };

  const handleChange = (selectedOption) => {
    setSelectedLocation(selectedOption.data);
  };

  return (
    <div className='main-container'>
      <div className='logo-container'>
        {/* <div className='logo-label'>360accounting.online</div> */}
        <div className='logo-label'>Fattu Concepts</div>
        <div className='select-container'>
          <Select
            options={LOCATION_LIST.map((item) => ({
              value: item.key,
              label: item.name,
              data: item,
            }))}
            onChange={handleChange}
            placeholder="Select Country"
            components={{ Option, SingleValue }}
            defaultValue={{
              value: DEFAULT_LOCATION.key,
              label: DEFAULT_LOCATION.name,
              data: DEFAULT_LOCATION,
            }}
            isSearchable={false}
            isMulti={false}
            autoFocus={false}
          />
        </div>
      </div>
      <div className='hero-container'>
        <div className='hero-content-container'>
          <div className='hero-heading-one'>Accountants</div>
          <div className='hero-heading-two'>for accountants and all business types.</div>
          <div className='hero-sub-heading-one'>Outsource accounting services and all business needs to us and work with</div>
          <div className='hero-sub-heading-two'>top-certified CPAs</div>
          {/* <div className='hero-sub-heading-two'>{priceLabel}</div> */}
          <div className='hero-sub-heading-three'>We allow <span className='hero-sub-heading-three-bold'>{firmTypeLabel}</span> to build their businesses by deploying a virtual team of highly-qualified accountants that specialize in <span className='hero-sub-heading-three-bold'>{countryLabel}</span> accounting and bookkeeping.</div>
          <button type='button' className='btn hero-contact-us-btn' onClick={() => { handleContactUsClick() }}>Request Quotation</button>
        </div>
        <div className='hero-img-container'>
          <img src={require('./assets/hero_img1.png')} className='img' alt='...' />
        </div>
      </div>
      <div class="expertise-container">
        <h1>Our Staff Expertise</h1>
        <div class="expertise-row-container">
          {!isEmpty(expertiseList) && expertiseList.map(e => (<div class="expertise-row-item-container">
            <div class="expertise-icon-container">
              <img src={e.img} class="img" alt='img' />
            </div>
            <h5 class="expertise-item-heading">{e.title}</h5>
            <div class="expertise-item-content">{e.content}</div>
          </div>))}
        </div>
      </div>
      <div className='automation-container'>
        <h1>Apps and Automation</h1>
        <div className='automation-sub-heading'>Our breadth of application and automation expertise is <span className='automation-sub-heading-bold'>unparalleled</span> in the industry's outsourcing providers.</div>
        <div className='automation-item-row-container'>
          {!isEmpty(automationList) && automationList.map(item => (<div className={selectedAutomation === item.id ? 'automation-item-row-item-sel' : 'automation-item-row-item'} onClick={() => { handleCategoryClick(item) }}>{item.name}</div>))}
        </div>
        {!isEmpty(selectedAutomation) && !isEmpty(selectedSubCategory) && <div className='automation-sub-cat-row-container'>{selectedSubCategory.map(e => <div className='automation-sub-cat-container'>
          <div className='automation-sub-cat-img-container'>
            <img src={e.img} className='img' alt='...' />
          </div>
          <div className='automation-sub-cat-label'>{e.name}</div>
        </div>)
        }
        </div>
        }
      </div>
      <div className='skills-container'>
        <h1>Our Accountants</h1>
        <div className='automation-sub-heading'>As per your budget plan and the skill-set you require from your virtual accountant, you can choose a junior, mid or senior level accountant</div>
        <table className='skills-table-container'>
          <tr className='skills-table-heading-row'>
            <th className='skills-table-heading-primary'>SKILL SETS</th>
            <th className='skills-table-heading-secondary'>Junior Level</th>
            <th className='skills-table-heading-secondary'>Mid Level</th>
            <th className='skills-table-heading-secondary'>Senior Level</th>
          </tr>
          {!isEmpty(skillsList) && skillsList.map(e => (
            <tr>
              <td className='skills-table-item-row-primary'>{e.skill}</td>
              {e.juniorLevel === true ? (<td className='skills-table-item-row-check'>
                <div className='skills-table-item-row-check-parent-img-container'>
                  <div className='skills-table-item-row-check-img-container'>

                    <img src={require('./assets/ic_tick.png')} className='img' alt='...' />
                  </div>
                </div>
              </td>) : (<td className='skills-table-item-row-check' />)}
              {e.midLevel === true ? (<td className='skills-table-item-row-check'>
                <div className='skills-table-item-row-check-parent-img-container'>
                  <div className='skills-table-item-row-check-img-container'>

                    <img src={require('./assets/ic_tick.png')} className='img' alt='...' />
                  </div>
                </div>
              </td>) : (<td className='skills-table-item-row-check' />)}
              {e.seniorLevel === true ? (<td className='skills-table-item-row-check'>
                <div className='skills-table-item-row-check-parent-img-container'>
                  <div className='skills-table-item-row-check-img-container'>

                    <img src={require('./assets/ic_tick.png')} className='img' alt='...' />
                  </div>
                </div>
              </td>) : (<td className='skills-table-item-row-check' />)}
            </tr>
          ))}
        </table>
      </div>
      <div className='pricing-container'>
        <h1>Pricing Plan</h1>
        <div className='automation-sub-heading'>Needs change as business grow. We'll help you market smarter from day one.</div>
        <div className='pricing-row-container'>
          {!isEmpty(pricingList) && pricingList.map(e => (
            <div className='pricing-row-container-item'>
              <div className='pricing-label'>{e.name}</div>
              <div className='pricing-price-parent-container'>
                <div className='pricing-request-quotation' onClick={() => { handleContactUsClick() }}>Request Quotation</div>
                {/* <div className='pricing-starting-label'>Starting From</div> */}
                {/* <div className='pricing-price-container'>
                  <div className='price-currency-label'>{e.currencyLabel}</div>
                  <div className='price-price-label'>{e.amount}</div>
                  <div className='price-price-hourly'>{e.duration}</div>
                </div> */}
              </div>
              {e.features.map(e1 => (
                <div className='pricing-feature-container'>
                  <div className='pricing-feature-check-container'>
                    <img src={require('./assets/ic_tick.png')} className='img' alt='...' />
                  </div>
                  <div className='pricing-feature-label'>{e1}</div>
                </div>
              ))}

            </div>
          ))}
        </div>
      </div>
      <div class="virtual-staff-container">
        <h1>Why Use Virtual Staff</h1>
        <div className='automation-sub-heading'>Understanding the Use of Offshore Accountants as Virtual Employees</div>
        <div className='virtual-staff-row-container'>
          <div className='virtual-staff-row-item'>
            <div className='expertise-icon-container'>
              <img src={require('./assets/ic_Direct_Cost_Save.png')} className='img' alt='...' />
            </div>
            <h3 className='vitual-staff-row-item-label'>Direct Cost Save</h3>
            <p className='vitual-staff-row-item-text'>Save as much as 60% with Virtual Employees delivering at least the same quality of work.</p>
          </div>
          <div className='virtual-staff-row-item'>
            <div className='expertise-icon-container'>
              <img src={require('./assets/ic_Kickstarter.png')} className='img' alt='...' />
            </div>
            <h3 className='vitual-staff-row-item-label'>Kickstarter</h3>
            <p className='vitual-staff-row-item-text'>For those who are not ready to hire internally, offshoring is the journey that will allow you to do so.</p>
          </div>
          <div className='virtual-staff-row-item'>
            <div className='expertise-icon-container'>
              <img src={require('./assets/ic_Employee_Handling.png')} className='img' alt='...' />
            </div>
            <h3 className='vitual-staff-row-item-label'>Employee Handling</h3>
            <p className='vitual-staff-row-item-text'>Let the Outsourcing Providers handling Employee handling issues. Now you are using a service user.</p>
          </div>
          <div className='virtual-staff-row-item'>
            <div className='expertise-icon-container'>
              <img src={require('./assets/ic_Capital_Cost_Saving.png')} className='img' alt='...' />
            </div>
            <h3 className='vitual-staff-row-item-label'>Capital Cost Saving</h3>
            <p className='vitual-staff-row-item-text'>No need to spend on extra office space, laptops, furniture and increased utilities.</p>
          </div>
        </div>
      </div>
      <div className='footer-parent-container'>
        <div className='certification-img-parent-container'>
          <div className='certification-img-container'>
            <img src={require('./assets/certification/quickbook_online1.png')} className='img' />
          </div>
          <div className='certification-img-container'>
            <img src={require('./assets/certification/quickbook_advance_online1.png')} className='img' />
          </div>
          <div className='certification-img-container'>
            <img src={require('./assets/certification/xero_advisor_certified.png')} className='img' />
          </div>
        </div>
        <div className='footer-container'>
          <div className='footer-brand-label'>Fattu Concepts</div>
          <div className='footer-brand-sub-label'>{new Date().getFullYear()}, All rights reserved.</div>
        </div>
      </div>

      <TawkMessengerReact
        propertyId="65d1d8968d261e1b5f6200a8"
        widgetId="1hmtrc3b2" />
    </div>
  );
}

export default App;
